<template>
  <div class="mymenu">
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" background-color="#1b3554" text-color="#fff" active-text-color="#409EFF" router :collapse="isCollapse">
      <el-menu-item>
        <span slot="title">{{ title }}</span>
      </el-menu-item>
      <el-menu-item index="/">
        <i class="el-icon-menu"></i>
        <span slot="title">{{ $t("menu.home") }}</span>
      </el-menu-item>
      <el-submenu index="/goods">
        <template slot="title">
          <i class="el-icon-truck"></i>
          <span>{{ $t("menu.goods") }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/goods/list">{{ $t("menu.list") }}</el-menu-item>
          <el-menu-item index="/goods/analyse">{{ $t("menu.analyse") }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/class">
        <template slot="title">
          <i class="el-icon-school"></i>
          <span>班级管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/class/classList">我的班级</el-menu-item>
          <el-menu-item index="/class/studentList">我的学生</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/chain">
        <template slot="title">
          <i class="el-icon-office-building"></i>
          <span>机构管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/chain/list">机构列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/user">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>用户管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/user/list">用户列表</el-menu-item>
          <el-menu-item index="/user/jurisdiction">权限配置</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/order">
        <template slot="title">
          <i class="el-icon-tickets"></i>
          <span>订单管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/order/list">订单列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/market">
        <template slot="title">
          <i class="el-icon-data-analysis"></i>
          <span>营销管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/market/coupon">优惠券模板</el-menu-item>
          <el-menu-item index="/market/group">拼团模板</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getChainDetails } from '../../../request/api'

export default {
  props: ['isCollapse'],
  data () {
    return {
      title: ''
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async init () {
      const res = await getChainDetails(this.loginData.chain_id)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.title = (res.data.data.title)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.mymenu {
  overflow-x: auto;
  height: 100%;
  .el-menu {
    border-right: 0;
    .is-active {
      background: #054476 !important;
      color: #fff !important;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
}
</style>