<template>
  <div class="content">
    <div class="header">
      <svg v-if="!isCollapse" class="icon" aria-hidden="true" @click="changeMenu">
        <use xlink:href="#icon-caidanguanli"></use>
      </svg>
      <svg v-else class="icon" aria-hidden="true" @click="changeMenu">
        <use xlink:href="#icon-caidan"></use>
      </svg>
      <div class="header-right">
        <el-dropdown @command="clickLang">
          <span class="el-dropdown-link">
            多语言<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <!-- 暂未开放 -->
            <!-- <el-dropdown-item command="en">English</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
        <div class="user">
          <span>欢迎您：{{loginData.name}}</span>
          <svg class="icon" aria-hidden="true" @click="loginOut">
            <use xlink:href="#icon-secede"></use>
          </svg>
        </div>
      </div>
    </div>
    <!-- 内容区域 -- 路由出口 -->
    <div class="content-route">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: ['isCollapse'],
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    ...mapMutations('m_login', ['clearUser']),
    changeMenu () {
      // 点击切换按钮 修改父组件数据
      this.$emit('changeCollapse')
    },
    clickLang (command) {
      this.$i18n.locale = command
    },
    loginOut () {
      this.clearUser()
      localStorage.removeItem('usersinfomation')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-flow: column;
  .header {
    height: 56px;
    background: #1e78bf;
    color: #fff;
    line-height: 56px;
    .icon {
      font-size: 40px;
      margin-left: 8px;
      height: 50px;
    }
    .header-right {
      float: right;
      display: flex;
      padding-right: 20px;
      .user {
        margin-left: 20px;
        display: flex;
        .icon {
          margin-left: 20px;
        }
      }
      .el-dropdown-link {
        color: #fff;
        // 光标为小手
        cursor: pointer;
      }
    }
  }
  .content-route {
    height: 1030px;
    background: #efefef;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
</style>